import { message, Modal } from 'antd';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { cloneDeep, intersection, uniq, without } from 'lodash';
import { action, computed, observable } from 'mobx';
import { api, getPrice, replacePath } from '../../utils';
import { RightSiderStore } from '../components/rightSider/store';
import type { IShoppingCartList } from './components/mainList/interface';

export default class Store {
  constructor() {
    this.getShoppingCartList();
    this.init();
    this.getUserInfo();
  }

  @observable public rightSiderStore = new RightSiderStore({ parent: this });

  @observable public loading = false;

  @observable public putAwayList = []; // 上架列表(id)

  @observable public putAwayListMap = {}; // 上架列表(id)map

  @observable public vendorAndGoodsIdMap = {}; // goods对应的skuIds

  @observable public soldOutList = [];// 下架列表(id)

  @observable public allIds = [];

  @observable public allVendorIds = [];

  @observable public allGoodsIds = [];

  @observable public allSkuIds = [];

  @observable public selectedIdsForShow = [];// 展示用，所有id集合

  @observable public selectedIds = []; // 实际操作需要的订单id，即skuId

  @observable public allCheckFlag = false; // 是否全选

  @observable public addShoppingCartIds = [];// 再次购买新增的购物车IDS

  @observable public associatedShopId = 0;

  @action
  public getUserInfo = async() => {
    const req = await request<{ associatedShopId: number; }>({
      method: 'GET',
      url: '/api/dashboard/user',
    });
    this.associatedShopId = req.associatedShopId;
  };

  // 获取URL中的参数
  public init = () => {
    const search = new URLSearchParams(window.location.href.split('?')[1]);
    const addShoppingCartIds = search.get('addShoppingCartIds');
    if (addShoppingCartIds) {
      this.addShoppingCartIds = addShoppingCartIds.split(',');
    }
  };

  // 实时计算selectNum和totalPrice
  @computed
  public get selectedData() {
    if (this.selectedIds.length === 0) {
      return {
        selectNum: 0,
        selectedTotalPrice: 0,
      };
    }
    const arr = [];
    this.putAwayList.forEach((item) => {
      if (item.goodsVos && item.goodsVos.length > 0) {
        item.goodsVos.forEach((itemS) => {
          if (itemS.skuVos && itemS.skuVos.length > 0) {
            itemS.skuVos.forEach((itemT) => {
              if (this.selectedIds.some((el) => el === itemT.mallShoppingCartId)) {
                arr.push(itemT);
              }
            });
          }
        });
      }
    });
    const selectNum = arr.reduce((prev, cur) => {
      return prev + cur.skuNum;
    }, 0);
    const selectedTotalPrice = arr.reduce((prev, cur) => {
      const _price = getPrice(cur.price, cur.skuDiscountPrice, cur.skuActivityPrice);
      return prev + (_price * cur.skuNum);
    }, 0);
    return {
      selectedTotalPrice,
      selectNum,
    };
  }

  // 勾选/反勾选订单
  @action public onItemCheckChange = (checked: boolean, type: string, vendorId: number, goodsId?: number, skuId?: number): void => {
    switch (type) {
      case 'vendorId':
        this.operationItem(checked, type, vendorId);
        break;
      case 'goodsId':
        this.operationItem(checked, type, vendorId, goodsId);
        break;
      case 'skuId':
        this.operationItem(checked, type, vendorId, goodsId, skuId);
        break;
    }
    if (this.selectedIdsForShow.length > 0 && this.selectedIdsForShow.length >= this.allIds.length) {
      this.allCheckFlag = true;
    } else {
      this.allCheckFlag = false;
    }
  };

  // 勾选/反勾选复选按钮
  @action public operationItem = (checked, type, vendorId?, goodsId?, skuId?) => {
    let arr = this.selectedIds;
    let arrShow = this.selectedIdsForShow;
    const curVendor = this.putAwayListMap;
    const allVendorIds = Object.keys(curVendor).length > 0 ? Object.keys(curVendor)
      .map(Number) : [];
    if (checked) {
      if (type === 'vendorId') {
        arrShow.push(vendorId);

        // 获取该vendor下的数据
        const allgoods = this.putAwayListMap[vendorId] || {};

        // 获取goodsId集合
        const allGoodsIds = Object.keys(allgoods).length > 0 ? Object.keys(allgoods)
          .map(Number) : [];

        // 获取skuId集合
        const allSkuIds = Object.values(allgoods).length > 0 ? Object.values(allgoods) : [];
        arr = arr.concat(...allSkuIds);
        arrShow = arrShow.concat(allGoodsIds, ...allSkuIds);
        if (allVendorIds.length > 0 && intersection(allVendorIds, arrShow).length === allVendorIds.length) {
          this.allCheckFlag = true;
        }
      }
      if (type === 'goodsId') {
        arrShow.push(goodsId);
        const curGoodsId = this.putAwayListMap[vendorId] || {};
        const alGoodsIds = Object.keys(curGoodsId).length > 0 ? Object.keys(curGoodsId)
          .map(Number) : [];
        const allSkuIds = this.putAwayListMap[vendorId][goodsId] || [];
        arr = arr.concat(allSkuIds);
        arrShow = arrShow.concat(allSkuIds);
        if (alGoodsIds.length > 0 && intersection(alGoodsIds, arrShow).length === alGoodsIds.length) {
          arrShow.push(vendorId);
        }
      }
      if (type === 'skuId') {
        arr.push(skuId);
        arrShow.push(skuId);
        const curGoodsId = this.putAwayListMap[vendorId] || {};
        const alGoodsIds = Object.keys(curGoodsId).length > 0 ? Object.keys(curGoodsId)
          .map(Number) : [];
        const curSkuids = this.putAwayListMap[vendorId][goodsId] || [];
        if (curSkuids.length > 0 && intersection(curSkuids, arrShow).length === curSkuids.length) {
          arrShow.push(goodsId);
        }
        if (alGoodsIds.length > 0 && intersection(alGoodsIds, arrShow).length === alGoodsIds.length) {
          arrShow.push(vendorId);
        }
        if (allVendorIds.length > 0 && intersection(allVendorIds, arrShow).length === allVendorIds.length) {
          this.allCheckFlag = true;
        }
      }
      this.selectedIds = uniq(arr);
      this.selectedIdsForShow = uniq(arrShow);
    } else {
      if (type === 'vendorId') {
        arr = arr.filter((item) => item !== vendorId);
        arrShow = arrShow.filter((item) => item !== vendorId);
        const allgoods = this.putAwayListMap[vendorId] || {};
        const allGoodsIds = Object.keys(allgoods).length > 0 ? Object.keys(allgoods)
          .map(Number) : [];
        const allSkuIds = Object.values(allgoods).length > 0 ? (Object.values(allgoods)
          .flat()) as number[] : [];
        const arrTmp = allGoodsIds.concat(allSkuIds);
        arr = without(arr, ...allSkuIds);
        arrShow = without(arrShow, ...arrTmp);
      }
      if (type === 'goodsId') {
        arr = arr.filter((item) => item !== goodsId);
        arrShow = arrShow.filter((item) => item !== goodsId);
        const allSkuIdsArr = this.putAwayListMap[vendorId][goodsId] || [];
        arr = without(arr, ...allSkuIdsArr);
        arrShow = without(arrShow, ...allSkuIdsArr, vendorId);
      }
      if (type === 'skuId') {
        arr = arr.filter((item) => item !== skuId);
        arrShow = without(arrShow, vendorId, goodsId, skuId);
      }
      this.selectedIds = uniq(arr);
      this.selectedIdsForShow = uniq(arrShow);
    }
  };

  // 勾选/反勾选全选按钮
  @action public onAllCheckChange = (checked: boolean): void => {
    // 如果表内无数据，不允许勾选
    if (this.putAwayList?.length === 0) {
      return;
    }
    if (checked) {
      this.selectedIds = this.allSkuIds;
      this.selectedIdsForShow = this.allIds;
    } else {
      this.selectedIds = [];
      this.selectedIdsForShow = [];
    }
    this.allCheckFlag = checked;
  };

  // 获取进货车list
  @action public getShoppingCartList = async() => {
    this.putAwayListMap = {};
    this.allIds = [];
    this.loading = true;
    try {
      const res = await request<BaseData<IShoppingCartList>>({ url: api.getShoppingCartList });
      this.loading = false;
      this.getPutAwayListMap(res.data?.putAwayList);

      this.putAwayList = res.data?.putAwayList || [];
      this.soldOutList = res.data?.soldOutList || [];

      // 根据跳转的ID匹配哪些款式需要选中
      if (this.addShoppingCartIds.length > 0) {
        this.selectedIds = this.addShoppingCartIds.map(Number);
        this.selectedIdsForShow = this.addShoppingCartIds.map(Number);
        this.putAwayList.map((vendor) => {
          vendor.goodsVos.map((goods) => {
            let skuMatch = 0;
            goods.skuVos.map((sku) => {
              skuMatch += this.selectedIds.filter((e) => e === sku.mallShoppingCartId).length;
            });
            if (skuMatch === goods.skuVos.length && skuMatch !== 0) {
              this.selectedIdsForShow.push(goods.goodsId);
              this.selectedIdsForShow.push(vendor.vendorId);
            }
          });
        });
      }

      // 显示affix组件
      window.scrollTo(0, 1);
    } catch (e) {
      this.loading = false;
      this.putAwayList = [];
      this.soldOutList = [];
    }
  };

  // 组装数据{
  //   vendorId: {
  //     goodsId: skuIds[]
  //   }
  // }
  @action public getPutAwayListMap = (list): any => {
    const objVendor = {};
    let objGoods = {};
    const vendorAndGoodsIdMap = {};
    const arr = []; // 获取所有vendorId、goodsId、skuId
    const arrSku = [];
    if (list.length === 0) {
      return {};
    }
    list.forEach((item) => {
      arr.push(item.vendorId);
      if (item.goodsVos && item.goodsVos?.length > 0) {
        item.goodsVos?.forEach((itemS) => {
          arr.push(itemS.goodsId);
          if (itemS.skuVos && itemS.skuVos?.length > 0) {
            const skuIds = itemS.skuVos?.map((itemT) => {
              if (!itemT.hideStatus) {
                arr.push(itemT.mallShoppingCartId);
                arrSku.push(itemT.mallShoppingCartId);
                return itemT.mallShoppingCartId;
              } else {
                return null;
              }
            })
              ?.filter((id) => id);
            Object.assign(objGoods, { [itemS.goodsId]: skuIds });
            Object.assign(objVendor, { [item.vendorId]: objGoods });
            Object.assign(vendorAndGoodsIdMap, { [itemS.goodsId]: skuIds });
          }
        });
        objGoods = {};
      }
    });
    this.putAwayListMap = objVendor;
    this.vendorAndGoodsIdMap = vendorAndGoodsIdMap;
    this.allIds = uniq(arr);
    this.allSkuIds = uniq(arrSku);
  };

  @action public getCurrentSkuIds = (curgoodsIds): any => {
    if (!curgoodsIds || curgoodsIds.length === 0) {
      return [];
    }

    let arr = [];
    curgoodsIds.forEach((item) => {
      if (this.vendorAndGoodsIdMap[item] && this.vendorAndGoodsIdMap[item].length > 0) {
        arr = arr.concat(this.vendorAndGoodsIdMap[item]);
      }
    });
    return uniq(arr);
  };

  @action public mapSelectList = (originList, key, value) => {
    return originList.map((item) => ({
      label: item[key],
      value: item[value],
    }));
  };

  // 二次确认弹窗
  @action public confirmAgain = (e, id) => {
    Modal.confirm({
      title: '提示',
      content: '确定删除商品？',
      getContainer: document.getElementById('modalContainer'),
      onOk: () => {
        this.deleteItems(id);
      },
    });
  };

  // 删除订单
  @action public deleteItems = (ids) => {
    request<BaseData<any>>({
      url: api.batchDeleteSku,
      method: 'post',
      data: { ids: ids.toString() },
    })
      .then((res) => {
        message.success('操作成功');
        this.selectedIdsForShow = without(this.selectedIdsForShow, ...ids);
        this.selectedIds = without(this.selectedIds, ...ids);
        this.getShoppingCartList();
      });
  };

  @action public limitNum = (value: string): string => {
    const reg = /^[+]{0,1}(\d+)$/;
    if (reg.test(value) && Number(value) > 0) {
      return `${value}`;
    } else {
      return '';
    }
  };

  @action public getCurrentItem = (vendorId, goodsId, skuId, value) => {
    return this.putAwayList.find((vendor) => `${vendor.vendorId}` === `${vendorId}`)
      .goodsVos
      .find((goods) => `${goods.goodsId}` === `${goodsId}`)
      .skuVos
      .find((sku) => `${sku.mallShoppingCartId}` === `${skuId}`);
  };

  @action public changeNum = (value, vendorId, goodsId, skuId) => {
    this.getCurrentItem(vendorId, goodsId, skuId, value).skuNum = value;
  };

  @action public blurChange = (e, vendorId, goodsId, skuId) => {
    const { value } = e.target;
    let num = value;
    if (!value) {
      num = 1;
      this.getCurrentItem(vendorId, goodsId, skuId, value).skuNum = 1;
    }
    this.updateSkuNum({
      mallShoppingCartId: skuId,
      skuNum: num,
    });
  };

  @action public changeNumCus = (e, type, value, vendorId, goodsId, skuId) => {
    let num = value;
    if (type === 'minus') {
      num--;
      if (num < 1) {
        num = 1;
      }
    } else {
      num++;
    }
    this.getCurrentItem(vendorId, goodsId, skuId, value).skuNum = num;

    // 当sku===1，做减法时不再调接口
    if (type !== 'minus' || value !== 1) {
      this.updateSkuNum({
        mallShoppingCartId: skuId,
        skuNum: num,
      });
    }
  };

  // 保存skuNum
  @action public updateSkuNum = (params): void => {
    request<BaseData<any>>({
      url: api.updateSkuNum,
      method: 'POST',
      data: params,
    });
  };

  // 提交订单
  @action public submitOrders = () => {
    const putAwayListClone = cloneDeep(this.putAwayList);
    const selectedIds = this.selectedIds;
    for (let i = 0; i < putAwayListClone.length; i++) {
      if (putAwayListClone[i].goodsVos && putAwayListClone[i].goodsVos.length > 0) {
        for (let j = 0; j < putAwayListClone[i].goodsVos.length; j++) {
          if (putAwayListClone[i].goodsVos[j].skuVos && putAwayListClone[i].goodsVos[j].skuVos.length > 0) {
            for (let k = 0; k < putAwayListClone[i].goodsVos[j].skuVos.length; k++) {
              // 如果selectedIds中不包含该skuId，或者包含但是skuNum === 0，则把该数据删除
              // eslint-disable-next-line no-loop-func
              if (!selectedIds.find((el) => el === putAwayListClone[i].goodsVos[j].skuVos[k].mallShoppingCartId) || putAwayListClone[i].goodsVos[j].skuVos[k].skuNum === 0) {
                putAwayListClone[i].goodsVos[j].skuVos.splice(k--, 1);
              } else {
                const data = putAwayListClone[i].goodsVos[j].skuVos[k];
                const _price = getPrice(data.price, data.skuDiscountPrice, data.skuActivityPrice);
                putAwayListClone[i].goodsVos[j].skuVos[k].totalPrice = Number(_price * data.skuNum)
                  .toFixed(2);
                putAwayListClone[i].goodsVos[j].skuVos[k].discountPrice = data.skuDiscountPrice;
                putAwayListClone[i].goodsVos[j].skuVos[k].activityPrice = data.skuActivityPrice;
              }
            }
          }
          if (putAwayListClone[i].goodsVos[j].skuVos.length === 0) {
            putAwayListClone[i].goodsVos.splice(j--, 1);
          }
        }
      }
      if (putAwayListClone[i].goodsVos.length === 0) {
        putAwayListClone.splice(i--, 1);
      }
    }
    sessionStorage.setItem('confirmGoodsList', JSON.stringify(putAwayListClone));
    sessionStorage.setItem('confirmOrigin', 'cart');
    if (this.associatedShopId) {
      window.open('/egenie-ts-vogue/distributor/confirmOrder/index');
    } else {
      window.open(replacePath('/egenie-ts-vogue/confirmOrder/index'));
    }
  };
}

export type IParentStore = Store;
