export const headerConfigList = [
  {
    name: '商品信息',
    width: '300px',
  },
  {
    name: '',
    width: '47px',
  },
  {
    name: '单价',
    width: '217px',
  },
  {
    name: '数量',
    width: '185px',
  },
  {
    name: '金额',
    width: '156px',
  },
  {
    name: '操作',
    width: '67px',
  },
];
