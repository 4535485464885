import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { setLocationHref } from '../../utils';
import { Footer, FooterLogo, Navigation, RightSider } from '../components';
import FooterWrap from './components/footer';
import MainList from './components/mainList';
import styles from './index.less';
import Store from './store';

const store = new Store();

@observer
export default class ShoppingCart extends Component {
  componentDidMount(): void {
    setLocationHref();
  }

  render(): JSX.Element {
    const { loading, rightSiderStore } = store;
    return (
      <>
        <div
          className={styles.contentWrap}
          id="cart"
        >
          <Navigation/>
          <div
            className={styles.shoppingCartWrapper}
            id="shoppingCartWrapper"
          >
            <h3 className={styles.titleShoppingCart}>
              进货车
            </h3>
            <MainList store={store}/>
            <FooterWrap store={store}/>
          </div>
        </div>
        <FooterLogo/>
        <Footer/>
        <RightSider store={rightSiderStore}/>
        <div
          className={styles.modalContainer}
          id="modalContainer"
        />
      </>
    );
  }
}
