import { Card, Button, Spin, Checkbox, Affix } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import styles from './index.less';

@observer
export default class FooterWrap extends Component<{ store; }> {
  render(): JSX.Element {
    const { allCheckFlag, onAllCheckChange, selectedIds, selectedIdsForShow, allIds, selectedData, confirmAgain, submitOrders } = this.props.store;
    return (
      <Affix offsetBottom={0}>
        <div className={styles.footerWrap}>
          <div className={styles.operationCheck}>
            <div style={{
              width: '48px',
              marginLeft: 16,
            }}
            >
              <Checkbox
                checked={allCheckFlag}
                indeterminate={selectedIdsForShow.length > 0 && selectedIdsForShow.length < allIds.length}

                onChange={(e) => {
                  onAllCheckChange(e.target.checked);
                }}
                style={{ marginRight: '8px' }}
              />
              全选
            </div>
            <Button
              className={styles.deleteItem}
              disabled={selectedData.selectNum === 0}
              onClick={(e) => {
                confirmAgain(e, selectedIds);
              }}
              style={{ color: selectedData.selectNum > 0 ? '#2B2E3E' : '#e2e2e5' }}
              type="link"
            >
              删除
            </Button>
          </div>
          <div className={styles.operationSubmit}>
            <div className={styles.selectOrders}>
              已选商品
              <span className={styles.selectedNum}>
                {selectedData.selectNum}
              </span>
              ，
              合计（不含运费）：
              <span className={styles.selectedTotalPrice}>
                ¥
                {Number(selectedData.selectedTotalPrice).toFixed(2)}
              </span>
            </div>
            <div className={styles.btnContainer}>
              {
                selectedData.selectNum === 0 ? (
                  <Button
                    disabled={selectedData.selectNum === 0}
                    type="primary"
                  >
                    提交订单
                  </Button>
                ) : (
                  <Button
                    onClick={submitOrders}
                    type="primary"
                  >
                    提交订单
                  </Button>
                )
              }
            </div>
          </div>
        </div>
      </Affix>
    );
  }
}
