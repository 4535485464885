import { RightOutlined } from '@ant-design/icons';
import { Affix, Button, Checkbox, InputNumber, Popover, Spin } from 'antd';
import { intersection } from 'lodash';
import { inject, observer, Provider } from 'mobx-react';
import { nanoid } from 'nanoid';
import React, { Component } from 'react';
import noPic from '../../../../assets/images/noPic.png';
import { getPrice, replacePath, splitPrice } from '../../../../utils';
import { PUBLIC_IMAGE_URL } from '../../../../utils/common';
import { BlankSegment, Price, Tag } from '../../../components';
import EmptyStatus from '../../../components/empty';
import type Store from '../../store';
import { headerConfigList } from './constant';
import styles from './index.less';
import type { ISelectOrder, onAllCheckChange, onCheckChange, openDetail, openDispatch, openModifyReceiveInfo, SkuVo } from './interface';

@observer
export default class extends Component<{ store?: Store; }> {
  render(): JSX.Element {
    return (
      <Provider store={this.props.store}>
        <Content/>
      </Provider>
    );
  }
}

@inject('store')
@observer
class Content extends Component<{ store?: Store; }> {
  render() {
    const {
      putAwayList,
      soldOutList,
      onItemCheckChange,
      onAllCheckChange,
      allCheckFlag,
      deleteItems,
      confirmAgain,
      limitNum,
      changeNum,
      changeNumCus,
      selectedIdsForShow,
      allIds,
      putAwayListMap,
      vendorAndGoodsIdMap,
      getCurrentSkuIds,
      selectedIds,
      blurChange,
      loading,
    } = this.props.store;
    return (
      <>
        <ListHeader
          allIds={allIds}
          checkFlag={allCheckFlag}
          onAllCheckChange={onAllCheckChange}
          selectedIds={selectedIds}
          selectedIdsForShow={selectedIdsForShow}
        />
        <Spin spinning={loading}>
          {loading ? <BlankSegment/> : (
            <div className={styles.listContent}>
              {
                (putAwayList.length !== 0 || soldOutList.length !== 0) ? (
                  <>
                    {
                      putAwayList.map((_item) => (
                        <div
                          className={styles.itemWrap}
                          key={_item.vendorId}
                        >
                          <ListItem
                            blurChange={blurChange}
                            changeNum={changeNum}
                            changeNumCus={changeNumCus}
                            confirmAgain={confirmAgain}
                            eachItem={_item}
                            getCurrentSkuIds={getCurrentSkuIds}
                            limitNum={limitNum}
                            onCheckChange={onItemCheckChange}
                            operateCollect={deleteItems}
                            putAwayListMap={putAwayListMap}
                            selectedIds={selectedIds}
                            selectedIdsForShow={selectedIdsForShow}
                            vendorAndGoodsIdMap={vendorAndGoodsIdMap}
                          />
                        </div>
                      ))
                    }
                    {
                      soldOutList && soldOutList.length > 0 && (
                        <SoldOutList
                          confirmAgain={confirmAgain}
                          soldOutList={soldOutList}
                        />
                      )
                    }

                  </>
                ) : (
                  <div className={styles.emptyWrapper}>
                    <EmptyStatus/>
                  </div>
                )
              }
            </div>
          )}
        </Spin>
      </>
    );
  }
}

@observer
class ListHeader extends Component<{ onAllCheckChange: onAllCheckChange; checkFlag: boolean; selectedIdsForShow; selectedIds; allIds; }> {
  render() {
    const {
      allIds,
      selectedIdsForShow,
      onAllCheckChange,
      checkFlag,
      selectedIds,
    } = this.props;
    return (
      <Affix>
        <div className={styles.listHeader}>
          <div style={{ width: '140px' }}>
            <Checkbox
              checked={checkFlag}
              indeterminate={selectedIds.length > 0 && selectedIdsForShow.length < allIds.length}
              onChange={(e) => {
                onAllCheckChange(e.target.checked);
              }}
              style={{ marginRight: '8px' }}
            />
            全选
          </div>
          {
            headerConfigList.map((item, index) => (
              <div
                key={nanoid()}
                style={{
                  width: item.width,
                  textAlign: index === 0 ? 'left' : 'center',
                }}
              >
                {item.name}
              </div>
            ))
          }
        </div>
      </Affix>
    );
  }
}

@observer
class ItemHeader extends Component<{ listItem: ISelectOrder; onCheckChange: onCheckChange; selectedIdsForShow; putAwayListMap; vendorAndGoodsIdMap; getCurrentSkuIds; }> {
  render() {
    const {
      listItem,
      onCheckChange,
      selectedIdsForShow,
      putAwayListMap,
      vendorAndGoodsIdMap,
      getCurrentSkuIds,
    } = this.props;
    const curgoodsIds = Object.keys(putAwayListMap[listItem.vendorId] || [])
      .map(Number);

    const skuIds = getCurrentSkuIds(curgoodsIds);
    const allVendorIds = curgoodsIds.concat(skuIds);
    const intersectionArr = intersection(selectedIdsForShow, allVendorIds);
    return (
      <div className={styles.itemHeader}>
        <div className={styles.itemHeaderWrap}>
          <Checkbox
            checked={selectedIdsForShow.filter((el) => el === listItem.vendorId).length > 0}
            indeterminate={selectedIdsForShow.length > 0 && intersectionArr.length > 0 && intersectionArr.length < allVendorIds.length}
            onChange={(e) => {
              onCheckChange(e.target.checked, 'vendorId', listItem.vendorId);
            }}
            style={{ margin: '0 8px 0 15px' }}
          />
          <span
            className={styles.vendorWrap}
            onClick={() => {
              window.open(replacePath(`/egenie-ts-vogue/stallDetail/index?vendorId=${listItem.vendorId}`));
            }}
          >
            档口：
            {listItem?.vendorName}
            <RightOutlined className={styles.rightOutlined}/>
          </span>
        </div>
      </div>
    );
  }
}

@observer
class ListItem extends Component<{
    eachItem: ISelectOrder;
    onCheckChange: onCheckChange;
    openDetail?: openDetail;
    openDispatch?: openDispatch;
    openModifyReceiveInfo?: openModifyReceiveInfo;
    operateCollect;
    confirmAgain;
    limitNum;
    changeNum;
    changeNumCus;
    selectedIdsForShow;
    putAwayListMap;
    vendorAndGoodsIdMap;
    getCurrentSkuIds;
    selectedIds;
    blurChange;
  }> {
  render(): JSX.Element {
    return (
      <div className={styles.item}>
        <ItemHeader
          getCurrentSkuIds={this.props.getCurrentSkuIds}
          listItem={this.props.eachItem}
          onCheckChange={this.props.onCheckChange}
          putAwayListMap={this.props.putAwayListMap}
          selectedIdsForShow={this.props.selectedIdsForShow}
          vendorAndGoodsIdMap={this.props.vendorAndGoodsIdMap}
        />
        <ItemContent
          blurChange={this.props.blurChange}
          changeNum={this.props.changeNum}
          changeNumCus={this.props.changeNumCus}
          confirmAgain={this.props.confirmAgain}
          getCurrentSkuIds={this.props.getCurrentSkuIds}
          limitNum={this.props.limitNum}
          listItem={this.props.eachItem}
          onCheckChange={this.props.onCheckChange}
          openDetail={this.props.openDetail}
          openDispatch={this.props.openDispatch}
          openModifyReceiveInfo={this.props.openModifyReceiveInfo}
          operateCollect={this.props.operateCollect}
          selectedIds={this.props.selectedIds}
          selectedIdsForShow={this.props.selectedIdsForShow}
        />
      </div>
    );
  }
}

@observer
class ItemContent extends Component<{
    listItem: ISelectOrder;
    openDetail: openDetail;
    openDispatch: openDispatch;
    openModifyReceiveInfo: openModifyReceiveInfo;
    operateCollect;
    confirmAgain;
    limitNum;
    changeNum;
    changeNumCus;
    onCheckChange;
    selectedIdsForShow;
    getCurrentSkuIds;
    selectedIds;
    blurChange;
  }> {
  render(): JSX.Element {
    const {
      listItem,
      limitNum,
      changeNum,
      changeNumCus,
      onCheckChange,
      selectedIdsForShow,
      confirmAgain,
      getCurrentSkuIds,
      selectedIds,
      blurChange,
    } = this.props;
    return (
      <div
        className={styles.itemContent}
      >

        <div className={styles.listContentLeftWrap}>
          {
            listItem.goodsVos ? listItem.goodsVos.map((_productItem, index) => {
              const allGoodsId = getCurrentSkuIds([_productItem.goodsId]);
              const intersectionArr = intersection(selectedIds, allGoodsId);
              return (
                <div
                  className={styles.productInfoWrap}
                  key={_productItem.goodsId}
                >
                  <div className={styles.productLeftInfo}>
                    <div className={styles.checkbox}>
                      <Checkbox
                        checked={selectedIdsForShow.filter((el) => el === _productItem.goodsId).length > 0}
                        indeterminate={selectedIds.length > 0 && intersectionArr.length > 0 && intersectionArr.length < allGoodsId.length}
                        onChange={(e) => {
                          onCheckChange(e.target.checked, 'goodsId', _productItem.vendorId, _productItem.goodsId);
                        }}
                        style={{
                          marginRight: '8px',
                          marginTop: '30px',
                        }}
                      />
                    </div>
                    <div
                      className={styles.productImgWrapper}
                      onClick={() => {
                        window.open(replacePath(`/egenie-ts-vogue/goodsDetail/index?goodsId=${_productItem.goodsId}`));
                      }}
                    >
                      <Popover
                        content={(
                          <img
                            src={_productItem.mainPicUrl || noPic}
                            style={{
                              width: 200,
                              height: 200,
                            }}
                          />
                        )}
                        placement="right"
                      >
                        <div className={styles.imgWrap}>
                          <img
                            className={styles.showPic}
                            src={_productItem.mainPicUrl || noPic}
                          />
                        </div>
                      </Popover>

                      <div className={styles.productItemInfo}>
                        <div
                          className={styles.productItemName}
                          title={_productItem.goodsName}
                        >
                          {_productItem.goodsName}
                        </div>
                        <div className={styles.tag}>
                          <Tag
                            returnRate={_productItem?.returnRate}
                            returnable={_productItem?.returnable}
                            type="return"
                          />
                          <div
                            className={styles.detailBtn}
                          >
                            详情
                            <img
                              alt=""
                              className={styles.rightArr}
                              src={`${PUBLIC_IMAGE_URL}rightArrowRed.png`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ProductItem
                    blurChange={blurChange}
                    changeNum={changeNum}
                    changeNumCus={changeNumCus}
                    confirmAgain={confirmAgain}
                    goodsId={_productItem.goodsId}
                    key={_productItem.skuNo}
                    limitNum={limitNum}
                    onCheckChange={onCheckChange}
                    selectedIdsForShow={selectedIdsForShow}
                    skuVos={_productItem?.skuVos || []}
                    vendorId={_productItem.vendorId}
                  />
                </div>
              );
            }) : null
          }
        </div>
      </div>
    );
  }
}

@observer
class ProductItem extends Component<{ skuVos: SkuVo[]; limitNum; changeNum; changeNumCus; goodsId; vendorId; onCheckChange; selectedIdsForShow; confirmAgain; blurChange; }> {
  render() {
    const {
      skuVos,
      limitNum,
      changeNum,
      changeNumCus,
      goodsId,
      vendorId,
      onCheckChange,
      selectedIdsForShow,
      confirmAgain,
      blurChange,
    } = this.props;
    return (
      <div className={styles.skusWrap}>
        {
          skuVos.map((item, index) => {
            const goodsSpec = item.goodsSpec.split(';');
            const actualPrice = getPrice(item.price, item.skuDiscountPrice, item.skuActivityPrice); // item.discountPrice ? item.discountPrice : item.price;
            const totalPrice = splitPrice(Number(actualPrice * item.skuNum)
              .toFixed(2));// splitPrice((item.discountPrice || item.discountPrice === 0) ? Number(item.discountPrice * item.skuNum).toFixed(2) : Number((item.price || 0) * item.skuNum).toFixed(2));
            return (
              <div
                className={`${styles.skuItem} ${(skuVos.length > 1 && index < skuVos.length - 1) ? styles.skuItemBorder : ''}`}
                key={item.mallShoppingCartId}
              >
                <div style={{ width: '68px' }}>
                  <Checkbox
                    checked={selectedIdsForShow.filter((el) => el === item.mallShoppingCartId).length > 0}
                    disabled={item.hideStatus}
                    onChange={(e) => {
                      onCheckChange(e.target.checked, 'skuId', vendorId, goodsId, item.mallShoppingCartId);
                    }}
                    style={{
                      marginRight: '28px',
                      marginLeft: '19px',
                    }}
                  />
                </div>
                <div className={item.hideStatus ? `${styles.skuStyle} ${styles.skuUnShelfStyle}` : styles.skuStyle}>
                  <div>
                    {goodsSpec[0]}
                  </div>
                  <div>
                    {goodsSpec[1]}
                  </div>
                </div>
                {item.hideStatus ? (
                  <div className={styles.skuUnShelfWrap}>
                    <div className={styles.skuUnShelf}>
                      该SKU已下架，请重新选择
                    </div>
                  </div>
                ) : (
                  <>
                    <div className={styles.price}>
                      <Price
                        activityPrice={item.skuActivityPrice}
                        discountPrice={item.skuDiscountPrice}
                        isShowText={false}
                        price={item.price}
                      />
                    </div>
                    <div className={styles.numWrap}>
                      <NumWrap
                        blurChange={blurChange}
                        changeNum={changeNum}
                        changeNumCus={changeNumCus}
                        goodsId={goodsId}
                        limitNum={limitNum}
                        skuId={item.mallShoppingCartId}
                        value={item.skuNum}
                        vendorId={vendorId}
                      />
                    </div>
                    <div className={styles.totalWrap}>
                      <span className={styles.priceIcon}>
                        &yen;
                      </span>
                      <span className={styles.intPart}>
                        {totalPrice.intPart}
                        .
                      </span>
                      <span>
                        {totalPrice.decimalPart}
                      </span>
                    </div>
                  </>
                )}
                <div className={styles.productOperation}>
                  <span
                    className={styles.deleteBtn}
                    onClick={(e) => {
                      confirmAgain(e, [item.mallShoppingCartId]);
                    }}

                  >
                    删除
                  </span>
                </div>
              </div>
            );
          })
        }
      </div>
    );
  }
}

class SoldOutList extends Component<{ soldOutList; confirmAgain; }> {
  render() {
    const {
      soldOutList,
      confirmAgain,
    } = this.props;
    return (
      <div className={styles.soldOutListWrap}>
        <h3>
          失效商品
        </h3>
        <div className={styles.soldOutListContent}>
          {soldOutList.map((_item) => (
            <div
              className={styles.soldOutListItem}
              key={_item.goodsId}
            >
              <div
                className={styles.productImgWrapper}
                onClick={() => {
                  window.open(replacePath(`/egenie-ts-vogue/goodsDetail/index?goodsId=${_item.goodsId}`));
                }}
              >
                <Checkbox
                  checked={false}
                  disabled
                  style={{ marginRight: '8px' }}
                />
                <Popover
                  content={(
                    <img
                      src={_item.mainPicUrl || noPic}
                      style={{
                        width: 200,
                        height: 200,
                      }}
                    />
                  )}
                  placement="right"
                >
                  <div className={styles.disabledGoodWrap}>

                    <div className={styles.imgWrap}>
                      <img
                        className={styles.showPic}
                        src={_item.mainPicUrl || noPic}
                      />
                    </div>
                    <div className={styles.disabledGood}>
                      失效
                    </div>
                  </div>
                </Popover>
                <div
                  className={styles.productItemInfo}
                >
                  <div
                    className={styles.productItemName}
                    title={_item.goodsName}
                  >
                    商品名称：
                    {_item.goodsName}
                  </div>
                  <div className={styles.detailBtn}>
                    详情
                    <img
                      alt=""
                      className={styles.rightArr}
                      src={`${PUBLIC_IMAGE_URL}rightArrowRed.png`}
                    />
                  </div>
                </div>
                {/* <div className={styles.skuStyle}/>
                <div className={styles.price}/>
                <div className={styles.numWrap}/>
                <div className={styles.totalWrap}/> */}
              </div>
              <div className={styles.soldProductOperation}>
                <Button
                  className={styles.deleteBtn}
                  onClick={(e) => {
                    confirmAgain(e, _item.mallShoppingCartIds.split(','));
                  }}
                  type="link"
                >
                  删除
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export const NumWrap = observer((props) => {
  const {
    value,
    limitNum,
    changeNum,
    blurChange,
    changeNumCus,
    goodsId,
    skuId,
    vendorId,
  } = props;
  return (
    <div className={styles.customNumWrap}>
      <Button
        disabled={value === 1}
        onClick={(e) => {
          changeNumCus(e, 'minus', value, vendorId, goodsId, skuId);
        }}
      >
        -
      </Button>
      <InputNumber
        className={styles.inputNumber}
        formatter={limitNum}
        min={1}
        onBlur={(value) => {
          blurChange(value, vendorId, goodsId, skuId);
        }}
        onChange={(value) => {
          changeNum(value, vendorId, goodsId, skuId);
        }}
        parser={limitNum}
        precision={0}
        style={{ width: 80 }}
        value={value}
      />
      <Button
        onClick={(e) => {
          changeNumCus(e, 'add', value, vendorId, goodsId, skuId);
        }}
      >
        +
      </Button>
    </div>
  );
});

